<template>
  <transition
    enter-active-class="transition duration-200 ease-out"
    enter-class="translate-y-[150%] opacity-0"
    leave-active-class="transition duration-200 ease-in"
    leave-to-class="translate-y-[150%] opacity-0"
  >
    <div v-if="show" class="fixed z-20 pt-4 pl-4 pr-5 pb-5 max-w-md md:left-1/2 md:-translate-x-1/2 rounded-2xl text-primaryText bg-brandHilight brand-hilight-back left-3 right-3 bottom-20 backdrop-filter backdrop-blur bg-opacity-90">
      <div class="mb-2 font-semibold">
        Cookie policy
        {{ cookie }}
      </div>
      <p class="text-xs leading-4">
        We use cookies to offer you a better browsing experience, analyze site traffic and personalize content. Read about how we use cookies by clicking
      </p>
      <div class="flex justify-end gap-4 mt-2.5 text-sm">
        <button class="text-secondaryText" @click="reject">
          Refuse
        </button>
        <button class="text-brand brand-main-text" @click="accept">
          Accept
        </button>
      </div>
    </div>
  </transition>
</template>

<script setup>
const expireDate = new Date()
expireDate.setDate(expireDate.getDate() + 365)
const cookie = useCookie('megeni-cookie', { expires: expireDate})

if (!cookie.value) cookie.value = 'undecided'

const reject = () => {
  cookie.value = 'rejected'
}
const accept = () => {
  cookie.value = 'accepted'
  const gtm = useGTM()
  if (gtm) gtm.enable(true)
}

const show = computed(() => cookie.value === 'undecided')
</script>
